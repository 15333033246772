<template>
  <div class="detail center">
    <bread-crumb></bread-crumb>
    <div class="detail-box">
	  <div class="btn-back">
	  	<el-button type="primary" @click="toPre" icon="el-icon-back" >返回</el-button>
	  </div>
      <article-show></article-show>
    </div>
  </div>
</template>
<script>
import breadCrumb from '@/components/common/breadCrumb'
import ArticleShow from "@/components/articleShow";

export default {
  components: {
    ArticleShow,
    breadCrumb
  },
  mounted() {
  },
  methods: {
    toPre() {
      this.$router.go(-1)
    }
  }
}
</script>
